<template>
  <div class="my-flex-container-column">
    <event-mappings-filter @active-change="filterActive = $event" :filter-model="filterModel"
                           @clear-filters="clearFilters"/>
    <el-row class="m-0" :gutter="20">
      <el-col :offset="4" :span="6">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="sortModel.pageSize"
          :current-page.sync="sortModel.pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div class="my-flex-data-content">
      <el-table v-loading="loading"
                style="width: 100%"
                height="100%"
                :data="events"
                :default-sort="this.sortModel"
                @sort-change="handleSortChange">

        <el-table-column width="64">
          <template v-slot="scope">
            <el-button type="text" icon="el-icon-search"
                       @click="handleCreate(scope.row.id)"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="№ события" width="150"
                         :formatter="eventNumberRenderer" sortable="custom"/>
        <el-table-column prop="eventTime" label="Дата и время" :formatter="dateTimeRenderer"
                         width="200" sortable="custom"/>
        <el-table-column prop="energySystem" label="Энергосистема" width="250"/>
        <el-table-column prop="energyObjects" label="Энергообъект"/>
        <el-table-column label="Оборудование/ЛЭП события">
          <template slot-scope="scope">
            {{ eventEquipment(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="baseVoltageName" label="Напряжение" width="120" sortable="custom"/>
      </el-table>
    </div>
    <event-mapping-modal :my-store="this.myModalStore" @delete="getEvents"></event-mapping-modal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
// import tableResize from '@/mixins/tableResize.js';
import dateHelpers from '@/mixins/dateHelpers';
import filterStorage from '@/utilities/filterStorage';
import EventMappingStore from '@/views/EventsAndIncidents/EventMapping2/EventMappingStore';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';
import EventMappingsFilter from '@/views/EventsAndIncidents/EventMapping2/EventMappingsFilter.vue';
import { set } from 'vue';
import EventMappingModalStore from '@/views/EventsAndIncidents/EventMapping/EventMappingModalStore';
import EventMappingModal from '@/views/EventsAndIncidents/EventMapping/EventMappingModal.vue';

export default {
  name: 'EventMapping',
  mixins: [formatters, dateHelpers],
  components: {
    EventMappingModal,
    EventMappingsFilter,
    pagination
  },

  watch: {
    filterModel: {
      handler(newVal) {
        this.getEvents();
        filterStorage.saveFilter('events-filter', newVal);
      },
      deep: true
    },
    sortModel: {
      handler(newVal) {
        this.getEvents();
        filterStorage.saveFilter('events-sort', newVal);
      },
      deep: true
    },
    estimations: {
      handler(est) {
        debugger;
       this.myModalStore.setEstimations(est);
      },
      deep: true
    },
    staffCategories: {
      handler(staff) {
        debugger;
        this.myModalStore.setStaffCategories(staff);
      },
      deep: true
    },
  },
  data() {
    return {
      loading: false,
      events: [],
      totalCount: 0,
      sortModel: filterStorage.getFilterOrDefault('events-sort', {
        prop: 'eventTime',
        order: 'descending',
        pageNumber: 1,
        pageSize: 20,
      }),
      filterModel: this.myStore.getEmpty(),
      filterActive: true,
      eventCard: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['estimations', 'staffCategories']),
    // filterModel: () => (this.myStore.state.filterModel)
  },
  beforeCreate() {
    this.myStore = new EventMappingStore();
  },
  async created() {
    this.myModalStore = new EventMappingModalStore(this.userHasRoleGroup('ExternalUser'), { toUserDate: this.toUserDate, toServerUtcDate: this.toServerUtcDate });
    const filter = filterStorage.getFilter('events-filter');
    const sort = filterStorage.getFilter('events-sort');
    if (filter) {
      // this.filterModel = { ...this.filterModel, ...filter };
      const empty = this.myStore.getEmpty();
      Object.keys(empty)
        .forEach((key) => {
          const value = filter[key] ? filter[key] : empty[key];
          set(this.filterModel, key, value);
        });
      if (sort) {
        // this.sortModel = { ...this.sortModel, ...sort };
        Object.keys(this.sortModel)
          .forEach((key) => {
            set(this.sortModel, key, sort[key]);
          });
      }
    } else {
      await this.getEvents(this.pageNumber, this.pageSize);
    }

    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    eventEquipment(row) {
      if (row.primaryEquipmentName && !row.secondEquipmentPanelName) {
        return row.primaryEquipmentName;
      }
      if (!row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return row.secondEquipmentPanelName;
      }
      if (row.primaryEquipmentName && row.secondEquipmentPanelName) {
        return `${row.secondEquipmentPanelName}; ${row.secondEquipmentPanelName}`;
      }
      return '';
    },
    async getEvents() {
      this.loading = true;


      const params = {
        pageNumber: this.sortModel.pageNumber,
        pageSize: this.sortModel.pageSize,
        startEventTimeRange: this.filterModel.startEventTimeFilter ? this.dateSetter(this.filterModel.startEventTimeFilter) : null,
        endEventTimeRange: this.filterModel.endEventTimeFilter ? this.dateSetter(this.filterModel.endEventTimeFilter) : null,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        energyObjectFilter: this.filterModel.energyObjectFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        primaryEquipmentFilter: this.filterModel.primaryEquipmentFilter,
        secondEquipmentPanelFilter: this.filterModel.secondEquipmentPanelFilter,
        dCOrganizationFilter: this.filterModel.dcOrganizationFilter,
        psrTypeFilter: this.filterModel.psrTypeFilter,
        estimationFilter: this.filterModel.estimationFilter,
        shortCircuitFormFilter: this.filterModel.shortCircuitFormFilter,
        shortCircuitPlaceFilter: this.filterModel.shortCircuitPlaceFilter,
        showDeleted: this.filterModel.showDeleted,
        numberFilter: this.filterModel.numberFilter,
        staffCategoryFilter: this.filterModel.staffCategoryFilter,
        accidentEquipmentFilter: this.filterModel.accidentEquipmentFilter,
        staffCulpabilitiesFilter: this.filterModel.staffCulpabilitiesFilter,
        technicalReasonsFilter: this.filterModel.technicalReasonsFilter,
        secondEquipmentTypesFilter: this.filterModel.secondEquipmentTypesFilter,
        misoperationReasonsFilter: this.filterModel.misoperationReasonsFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        producerOrganizationsFilter: this.filterModel.producerOrganizationsFilter,
        cardInformationFilter: this.filterModel.cardInformationFilter,
        executionsFilter: this.filterModel.executionsFilter,
        productAssetModelsFilter: this.filterModel.productAssetModelsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        faultedPhasesFilter: this.filterModel.faultedPhasesFilter,
        bmmnCheckTypesFilter: this.filterModel.bmmnCheckTypesFilter,
        onlyPrimaryEquipments: false,
        sortField: this.sortModel.prop,
       // correlationStatuses: this.filterModel.correlationStatuses,
        showNeedCorrect: this.filterModel.showNeedCorrect,
        sortDesc: this.sortModel.order === 'descending'
      };

      // if (this.filterModel.eventDateRange) {
      //   params.startEventTimeRange = this.filterModel.eventDateRange[0] ? this.dateSetter(this.filterModel.eventDateRange[0]) : null;
      //   params.endEventTimeRange = this.filterModel.eventDateRange[1] ? this.dateSetter(this.filterModel.eventDateRange[1]) : null;
      // }

      if (this.userHasRole('IncidentsDoneView')) {
        params.showNeedCorrect = false;
        this.filterModel.showNeedCorrect = false;
      }


      // const getAction = this.userHasRoleGroup('ExternalUser')
      //   ? udEventsApi.getEventsWithPagination
      //   : eventsApi.getEventsWithPagination;

      // if (this.userHasRoleGroup('ExternalUser')) {
      //   params = {
      //     ...params,
      //     showNeedCorrect: this.filterModel.showNeedCorrect
      //   };
      // }


      const action = this.userHasRoleGroup('ExternalUser')
        ? EventCardCorrelationRepository.getIntEventCardCorrelationPagingAsync
        : EventCardCorrelationRepository.getExtEventCardCorrelationPagingAsync;

      const res = await action(params); // EventCardCorrelationRepository.getPagingAvailableEventCardAsync(params);
      if (res.data) {
        this.events = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange(event) {
      this.sortModel.pageNumber = 1;
      this.sortModel.pageSize = event;
      await this.getEvents();
    },
    async handleCurrentChange(event) {
      this.sortModel.pageNumber = event;
      await this.getEvents();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortModel.order = null;
        this.sortModel.prop = null;
      } else {
        this.sortModel.order = event.order;
        this.sortModel.prop = event.prop;
      }
    },
    clearFilters() {
      this.filterModel = this.myStore.getEmpty();
      if (this.userHasRole('IncidentsDoneView')) {
        this.filterModel.showNeedCorrect = false;
      }
    },
    async handleCreate(eventCardId) {
      await this.myModalStore.createByEventCardId(eventCardId);
    },
  },
};
</script>

<style scoped>
.el-table:before {
  height: 0px;
}
</style>
